<template>
  <div v-if="inactive === false">
    <label class="txt-inp" :class="size">
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        @keypress="isLetter($event)"
        @keyup.delete="deleteLetter()"
        @change="Valid()"
        :class="alertMsgStatus == true ? 'invalid' : '' + size"
        class="w-100"
        title=""
        :placeholder="$t(placeholder)"
        ref="input"
      />
      <p
        class="label-text bg-white"
        :class="device == 'mobile' ? 'fnt-caption' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label) }}
        <span class="sub">{{ labelSub }}</span>
        <span class="require ms-1" v-if="request === true">*</span>
      </p>
    </label>
    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
    <div
      :class="alertLength == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertLength == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertLengthMsg) }}
    </div>
  </div>

  <div class="txt-inp disable-inp" v-else-if="inactive === true">
    <label class="txt-inp">
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value == '' || value == undefined ? strResult : value"
        @keypress="isLetter($event)"
        @change="Valid()"
        :class="size"
        class="w-100"
        title=""
        disabled
        :placeholder="$t(placeholder)"
        ref="input"
      />
      <p
        class="rounded-2 bg-white"
        :class="
          value == nul || value == undefined || value == ''
            ? 'movedow'
            : 'label-text'
        "
        :style="device == 'mobile' ? 'font-size:12px !important;' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label)
        }}<span class="sub">{{ labelSub }}</span>
        <span class="require" v-if="request === true"> *</span>
      </p>
    </label>
    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
    <div
      :class="alertLength == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertLength == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertLengthMsg) }}
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      inactive: false,
      strResult: "",
      alertLength: false,
      alertLengthMsg: "",
    };
  },

  components: {
    Icon,
  },

  props: {
    request: { type: Boolean, default: false },
    name: { type: String },
    label: { type: String },
    value: { type: String },
    statename: { type: String },
    maxlength: { type: String },
    validate: { type: String, default: "" },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    size: { type: String },
    placeholder: { type: String, default: "" },
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }
  },
  watch: {
    showTxtInp: {
      deep: true,
      handler(newValue) {
        this.inactive = newValue;
      },
    },
  },
  methods: {
    // changeCurrency(value) {
    //   value = parseInt(value)
    //     .toFixed(2)
    //     .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    // },
    ValidNumber(value) {
      return isNaN(value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        if (this.alertMsgStatus == true) {
          this.Valid();
        }
      }
    },
    deleteLetter() {
      // console.log("Valid");
      this.alertMsgStatus = false;
      if (this.value != undefined) {
        if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
    },
    alertRequest() {
      // this.alertMsg, { fieldname: this.$t(this.label).toLowerCase() };
      if (this.value == null || this.value == "") {
        this.alertMsgInput = this.$t(this.alertMsg, {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.alertMsgStatus = true;
        // console.log("alertMsgStatus :: " + this.alertMsgStatus);
        // console.log("alertMsgInput :: " + this.alertMsgInput);
      } else {
        this.Valid();
      }
    },
    alertDataDuplicate(msg) {
      this.alertMsgInput = this.$t(msg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    Valid() {
      // console.log(this.value);
      // this.alertMsgStatus = false;
      this.alertLength = false;
      if (this.value === "" || this.value === undefined) {
        return;
      } else {
        if (this.validate == "email") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          // console.log(this.alertMsgStatus);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "57";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "number") {
          this.alertMsgStatus = !Validation[this.validate](this.value);

          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "56";
            // console.log(this.alertMsgStatus);
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "numeric") {
          this.alertMsgStatus = !Validation[this.validate](this.value);

          if (this.alertMsgStatus == true) {
            // console.log(this.alertMsgStatus);
            this.alertMsgInput = "899";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "grade") {
          this.alertMsgStatus = !Validation[this.validate](this.value);

          if (this.alertMsgStatus == true) {
            // console.log(this.alertMsgStatus);
            this.alertMsgInput =
              this.$i18n.locale == "th"
                ? "กรุณากรอกตัวเลขไม่เกิน 4.00"
                : "Please enter a number not exceeding 4.00.";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "phonenumber") {
          this.alertMsgStatus = !Validation[this.validate](this.value);

          if (this.alertMsgStatus == true) {
            // console.log(this.alertMsgStatus);
            this.alertMsgInput = "899";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "webUrl") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "59";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "facebookpage") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "58";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "letterTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "letterEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "InitialTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "InitialEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "TextDisplayTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "TextDisplayEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "addressTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "currency") {
          this.alertMsgStatus = false;
          this.$refs["input"].value = Intl.NumberFormat("en-US").format(
            this.value.replaceAll(",", "")
          );
          return Validation[this.validate](this.value);
        } else if (this.validate == "letterENUnderScore") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "EducationTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "EducationEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else {
          if (this.validate !== "") {
            // console.log(this.validate);
            this.alertMsgStatus = !Validation[this.validate](this.value);
            // console.log(Validation[this.validate](this.value));
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = this.$t("202", {
                fieldname: this.$t(this.label).toLowerCase(),
              });
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](this.value);
            }
          }
        }
      }
    },
    isLetter(e) {
      this.alertMsgStatus = false;
      // console.log(this.maxlength);
      if (this.value != undefined && this.maxlength != undefined) {
        // console.log("this.value.length = " + this.value.length);
        if (this.value.length + 1 > this.maxlength) {
          // this.alertLength = true;
          // console.log("เกิน");
          // this.alertLengthMsg = "427";
          // setTimeout(() => {
          //   this.alertLength = false;
          // }, 2000);
        } else if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
      if (this.validate != null) {
        let char = String.fromCharCode(e.keyCode);
        // console.log(char);
        if (
          this.validate == "letterEN" ||
          this.validate == "letterTH" ||
          this.validate == "letterENUnderScore"
        ) {
          if (
            this.validate == "letterEN" ||
            this.validate == "letterENUnderScore"
          ) {
            this.alertMsgStatus = !Validation[this.validate](char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "46";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          } else if (this.validate == "letterTH") {
            // console.log(char);
            // console.log(/^[฿]+$/.test(char));
            if (/^[฿]+$/.test(char) == false) {
              this.alertMsgStatus = !/[ก-๏0-9- .]+$/.test(char);
              ///^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?ก-๏0-9-]+$/
              ///^[ก-๏0-9!@#\$%\^\&*\)\(+=._-]+$/g
              if (this.alertMsgStatus == true) {
                this.alertMsgInput = "45";
                e.preventDefault();
                return true;
              } else if (this.alertMsgStatus == false) {
                return Validation[this.validate](char);
              }
            } else if (/^[฿]+$/.test(char) == true) {
              this.alertMsgStatus = true;
              this.alertMsgInput = "45";
              e.preventDefault();
              return true;
            }
          }
        } else if (this.validate == "InitialEN") {
          if (/^[A-Za-z-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "231";
            e.preventDefault();
          }
        } else if (this.validate == "InitialTH") {
          if (/^[ก-๏-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "232";
            e.preventDefault();
          }
        } else if (this.validate == "TextDisplayEN") {
          if (/^[0-9A-Za-z-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "46";
            e.preventDefault();
          }
        } else if (this.validate == "TextDisplayTH") {
          if (/^[0-9ก-๏-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "45";
            e.preventDefault();
          }
        } else if (this.validate == "addressTH") {
          if (/^[ก-๏0-9,-/ .-–]+$/.test(char) && /^[฿]+$/.test(char) == false) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "45";
            e.preventDefault();
          }
        } else if (this.validate == "addressEN") {
          if (/^[a-zA-Z0-9,-/ .-–]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "46";
            e.preventDefault();
          }
        } else if (this.validate == "EducationEN") {
          if (/^[A-Za-z() -]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "46";
            e.preventDefault();
          }
        } else if (this.validate == "EducationTH") {
          if (/^[ก-๏() -]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "45";
            e.preventDefault();
          }
        }
        // else if (this.validate == "email") {
        //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(char)) {
        //     this.alertMsgStatus = false;
        //   } else {
        //     this.alertMsgStatus = true;
        //     this.alertMsgInput = "57";
        //     e.preventDefault();
        //   }
        // }
        else if (this.validate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "numeric") {
          if (/^[-0-9.]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "899";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "grade") {
          if (/^[0-9.][0-9.]*$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "899";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "phonenumber") {
          if (/^[-0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "899";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "currency") {
          if (/^[0-9.]+$/.test(char)) {
            this.alertMsgStatus = false;
            //console.log(this.$refs["input"].value);
            if (this.value.length > 0) {
              // setTimeout(() => {
              if (
                /^(0|[1-9][0-9]{0,2})*(\.)*(\d{1,1})?$/.test(
                  this.value.replaceAll(",", "")
                )
              ) {
                this.alertMsgStatus = false;
                return false;
              } else {
                this.alertMsgInput = "443";
                this.alertMsgStatus = true;
                e.preventDefault();
                return true;
              }
              // }, 0.5);
            }
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        }
        // else if (this.validate == "webUrl") {
        //   // console.log(e.keyCode);
        //   if (/^(https?:\/\/[^\s]+)/.test(char)) {
        //     this.alertMsgStatus = false;
        //     return false;
        //   } else {
        //     this.alertMsgInput = this.$t("202", {
        //       fieldname: this.$t(this.label).toLowerCase(),
        //     });
        //     this.alertMsgStatus = true;
        //     e.preventDefault();
        //     return true;
        //   }
        // }
        else if (
          this.validate !== "" &&
          this.validate !== "webUrl" &&
          this.validate !== "email" &&
          this.validate !== "facebookpage"
        ) {
          // console.log(this.validate);
          this.alertMsgStatus = !Validation[this.validate](char);
          // console.log(Validation[this.validate](char));
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = this.$t("202", {
              fieldname: this.$t(this.label).toLowerCase(),
            });
            e.preventDefault();
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](char);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.medium {
  height: 38px !important;
  .label-text {
    font-size: 14px !important;
    padding: 0 4px !important;
  }
  input {
    outline: 0;
    font-size: 14px !important;
    padding: 10px 16px !important;
    border: solid 1px #cacaca;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    &::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: red;
    }
    &:focus {
      border: solid 1px $primary;
      & + p {
        top: 0% !important;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 12px !important;
        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
    }
    &:valid + p {
      // color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 12px !important;
      top: 0% !important;
      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
    &:valid {
      // border: solid 1px $primary;
    }
  }
}
.disable-inp {
  input {
    padding: 10px 16px !important;
    &:hover {
      border: solid 1px $black-400;
    }
  }
  .label-text {
    top: -6% !important;
    background-color: $black-200 !important;
    color: $black-500 !important;
    font-size: 14px !important;
  }
  .movedow {
    // top: -6% !important;
    background-color: $black-200 !important;
    color: $black-500 !important;
    font-size: 14px !important;
  }
  input:disabled {
    background-color: $black-200 !important;
    color: $black;
    border: solid 1px $black-400 !important;
  }
}
.txt-inp {
  position: relative;
  width: 100%;
  p {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    color: $black-500;
    cursor: text;
    display: flex;
    user-select: none;
  }

  input {
    outline: 0;
    font-size: 16px;
    padding: 10px 17px !important;
    border: solid 1px $black-500;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    padding-top: 4px;
    &::placeholder {
      color: $white;
    }
    &:hover {
      border: solid 1px $primary;

      & + p {
        color: $primary;
      }
    }

    &:focus {
      border: solid 1px $primary;

      & + p {
        top: -4%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;

        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -4%;

      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
  }
}
</style>
